import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SchemaTable from "../components/schemaTable/SchemaTable";
import ParamsTable from "../components/paramsTable/ParamsTable";
import dets from "../../dets.js";
import Uris from "../components/uris/Uris";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="GTFS" mdxType="Seo" />
    <h1>{`GTFS`}</h1>
    <p>{`These URIs correspond to the official .txt files available from Transport for Ireland.`}</p>
    <p>{`For full GTFS Static spec see `}<a parentName="p" {...{
        "href": "https://gtfs.org/reference/static"
      }}>{`gtfs.org`}</a></p>
    <h2>{`Routes`}</h2>
    <table>
  <caption className="sr-only">/routes examples</caption>
  <thead>
    <tr>
      <th>Example uri</th>
      <th>Response</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>/api/busroutes</td>
      <td>All bus routes</td>
    </tr>
    <tr>
      <td>
        <code>/api/busroutes?route_short_name=404</code>
      </td>
      <td>
        Route with <code>route_short_name=404</code>
      </td>
    </tr>
  </tbody>
    </table>
    <h2>{`Stops`}</h2>
    <table>
    <caption className="sr-only">/stops examples</caption>
	<thead>
		<tr>
			<th>Example uri</th>
			<th>Response</th>
		</tr>
	</thead>
  <tbody>
		<tr>
    	<td><code>/api/stops</code></td>
    	<td>All bus stops</td>
    </tr>
    <tr>
    	<td><code>/api/stops?stop_id=8460B5213401</code></td>
    	<td>Stop with <code>stop_id=8460B5213401</code></td>
    </tr>
	</tbody>
    </table>
    <h2>{`StopTimes`}</h2>
    <table>
    <caption className="sr-only">/stoptimes examples</caption>
  <thead>
    <tr>
      <th>Example uri</th>
      <th>Response</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <code>/api/stoptimes</code>
      </td>
      <td>Empty Array (too many results)</td>
    </tr>
    <tr>
      <td>
        <code>
          /api/stopimes?stop_id=8460B5230101&after=20&sort=arrival_time&order=1
        </code>
      </td>
      <td>
        StopTimes for stop id 8460B5230101 after 8pm sorted by arrival_time
        increasing
      </td>
    </tr>
  </tbody>
    </table>
    <h2>{`Trips`}</h2>
    <table>
    <caption className="sr-only">/trips examples</caption>
  <thead>
    <tr>
      <th>Example uri</th>
      <th>Response</th>
    </tr>
  </thead>
  <tbody>
		<tr>
    <td>
      <code>/api/trips</code>
    </td>
    <td>All Trips</td>
  </tr>
  <tr>
    <td>
      <code>/api/trips?route_id=10-401-e19-1&direction_id=1</code>
    </td>
    <td>
      Trips for <code>route_id=10-401-e19-1</code> going in{" "}
      <code>direction_id=1</code>
    </td>
  </tr>
	</tbody>
    </table>
    <h2>{`Calendar`}</h2>
    <table>
    <caption className="sr-only">/calendar examples</caption>
  <thead>
    <tr>
      <th>Example uri</th>
      <th>Response</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <code>/api/calendar</code>
      </td>
      <td>All Calendar</td>
    </tr>
    <tr>
      <td>
        <code>
          /api/calendar?monday=1&start_before=20220307&end_after=20220307
        </code>
      </td>
      <td>
        service_ids running on Mondays with schedule beginning before, and
        ending after Mon 7th March 2022
      </td>
    </tr>
  </tbody>
    </table>
    <h2>{`CalendarDates`}</h2>
    <table>
    <caption className="sr-only">/calendardates examples</caption>
  <thead>
    <tr>
      <th>Example uri</th>
      <th>Response</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <code>/api/calendardates</code>
      </td>
      <td>All Calendar Dates</td>
    </tr>
    <tr>
      <td>
        <code>/api/calendardates?service_ids=y104h,y104f,y104a</code>
      </td>
      <td>
        Calendar Dates with service_ids in{" "}
        <code>["y104h", "y104f", "y104a"]</code>
      </td>
    </tr>
  </tbody>
    </table>
    <h2>{`Shapes`}</h2>
    <table>
    <caption className="sr-only">/shapes examples</caption>
  <thead>
    <tr>
      <th>Example uri</th>
      <th>Response</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <code>/api/shapes</code>
      </td>
      <td>All Shapes</td>
    </tr>
    <tr>
      <td>
        <code>/api/shapes?shape_id=10-401-e19-1.1526.O</code>
      </td>
      <td>
        Shapes with <code>shape_id=10-401-e19-1.1526.O</code>
      </td>
    </tr>
  </tbody>
    </table>
    <h2>{`Agency`}</h2>
    <table>
    <caption className="sr-only">/agency examples</caption>
  <thead>
    <tr>
      <th>Example uri</th>
      <th>Response</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <code>/api/agency</code>
      </td>
      <td>All agencies</td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      